<script setup>
import { checkForm } from '@/utilities/vue';
import { useActorHeadshotStore } from '@/stores/actor_headshots';
import { cloneDeep } from '@/utilities/helpers';
import { confirm, showError } from '@/dialogs';
import { computed, getCurrentInstance, nextTick, ref, watch } from 'vue';
import ActorHeadshot from '@/components/ActorHeadshot.vue';
import { useUppy } from '@/composables/uploads.js';
import { HEADSHOT_FORMATS } from '@/utilities/constants.js';
import { isGraphQLError } from '@/api/utils.js';
import { ReportError } from '@/error_handling.js';
import { ConvertPDF } from '@/utilities/convert_pdf';
import { extractHeadshotName } from '@/utilities/headshots.js';

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
  file: {
    type: File,
    default: null,
  },
});

const emit = defineEmits(['close']);

const actorHeadshotsStore = useActorHeadshotStore();

const imageUrl = ref(null);

const editingRecord = ref({});

const importingPDF = ref(false);
const headshotLabel = computed(() => `Headshot ${importingPDF.value ? ' (Importing PDF...)' : ''}`);

const title = computed(() => props.record.id ? 'Edit actor headshot' : 'New actor headshot');

const uppy = useUppy({
  idName: 'headshot_uploader',
  allowedTypes: HEADSHOT_FORMATS,
  startUploader(file) {
    return actorHeadshotsStore.startUpload({
      actorHeadshotId: editingRecord.value.id,
      contentType: file.type,
      fileName: file.name,
    });
  },
  thumbnailSize: [96, 96],
  thumbnailReady(preview, _file) {
    imageUrl.value = preview;
  },
});

watch(
  () => props.record,
  () => editingRecord.value = cloneDeep(props.record),
  { immediate: true },
);

// on mount?

if (props.file) {
  handleUpload(props.file);
}

/// methods

function cancel() {
  emit('close', { action: 'cancel' });
}

const vm = getCurrentInstance();

async function saveUpload() {
  if (uppy.value.getFiles().length) {
    const uploadStatus = await uppy.value.upload();
    if (uploadStatus.failed.length > 0) return false;
  }
  return true;
}

async function saveRecord() {
  const invalid = checkForm(vm);
  if (invalid.length === 0) {
    try {
      const record = await actorHeadshotsStore.save(editingRecord.value);
      if (!props.record.id) {
        editingRecord.value = cloneDeep(record);
      }
      if (!await saveUpload()) {
        showError({ message: 'File upload failed' });
        return;
      }

      emit('close', { action: 'save', record });
    } catch (ex) {
      if (isGraphQLError(ex)) {
        showError({ message: ex.message });
      }
      ReportError(ex);
    }
  } else {
    console.info(invalid);
    showError({ message: 'Form is not valid! Please check the fields.' });
  }
}

async function deleteRecord() {
  const result = await confirm({
    message: 'Delete this headshot?',
  });

  if (result.action === 'ok') {
    try {
      const actorHeadshotId = await useActorHeadshotStore().delete(editingRecord.value.id);
      emit('close', { action: 'delete', actorHeadshotId });
    } catch (ex) {
      if (isGraphQLError(ex)) {
        showError({ message: ex.message });
      }
      ReportError(ex);
    }
  }
}

async function handleUpload(file) {
  if (!file) return;

  uppy.value.clear();

  if (!editingRecord.value.name) {
    const name = extractHeadshotName(file);
    if (name) {
      editingRecord.value.name = name;
    }
  }

  if (file.type === 'application/pdf') {
    importingPDF.value = true;
    await nextTick();
    try {
      file = await (new ConvertPDF()).process(file);
    } catch (ex) {
      showError({
        message: 'PDF Loading not supported on this browser. Please upload a JPEG or PNG',
      });
      ReportError(ex, { tags: { handled: true } });
    }
  }

  try {
    uppy.value.addFile(file);
  } catch (ex) {
    showError({ message: ex.message });
    ReportError(ex);
  } finally {
    importingPDF.value = false;
  }
}

</script>

<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title {{ title }}
  section.modal-card-body
    OField(label="Name")
      OInput(v-model.trim="editingRecord.name" required)
    OField(:label="headshotLabel")
      figure.image.is-96x96
        img(
          v-if="imageUrl"
          :src="imageUrl"
          style="width: 96px; height: 96px; object-fit: contain; object-position: 50% 50%;"
        )
        ActorHeadshot(v-else-if="editingRecord.id" :actor-headshot-id="editingRecord.id")
        OSkeleton(v-else :animated="false" width="96px" height="96px")
      OUpload(
        drag-drop
        :accept="HEADSHOT_FORMATS.join(',')"
        @update:model-value="handleUpload"
      )
        p.upload-help
          OIcon(icon="upload")
          br
          | Drop here or click to upload
          br
          i a single image up to 5MB in size
  footer.modal-card-foot
    OButton(@click="cancel") Cancel
    OButton(variant="primary" @click="saveRecord") Save
    .bottom-right-actions
      OButton(v-if="editingRecord.id" variant="danger" @click="deleteRecord") Delete
</template>

<style scoped>
</style>
