<script setup>
import { colorProps } from '@/utilities/colors.js';
import { computed } from 'vue';
import { useSkillStore } from '@/stores/skills.js';

const skillsStore = useSkillStore();

const props = defineProps({
  skillIds: {
    type: [Array, Set],
    default() {
      return [];
    },
  },
  emptyMessage: {
    type: String,
    default: null,
  },
  deletable: Boolean,
});

defineEmits(['delete']);

const skills = computed(() => {
  return Array.from(props.skillIds).map(id => skillsStore.retrieve(id)).filter(c => c);
});

</script>

<template lang="pug">
.tags(v-if="skills.length > 0")
  span.tag(
    v-for="skill in skills"
    :key="skill.id"
    :class="colorProps(skill.options.colorName)?.classes"
  )
    strong
      | {{ skill.category }}:
      |
    | {{ skill.skill }}
    button.delete(v-if="deletable" @click="$emit('delete', skill)")
.mb-2(v-else-if="emptyMessage")
  i {{ emptyMessage }}
</template>

<style scoped>

</style>
