<script setup>
import { checkForm } from '@/utilities/vue';
import { useActorVideoStore } from '@/stores/actor_videos.js';
import { cloneDeep } from '@/utilities/helpers';
import { confirm, showError } from '@/dialogs';
import { computed, getCurrentInstance, nextTick, ref, watch } from 'vue';
import { isGraphQLError } from '@/api/utils.js';
import { ReportError } from '@/error_handling.js';
import trimStart from 'lodash/trimStart.js';

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const actorVideosStore = useActorVideoStore();

const editingRecord = ref({});

const urlRef = ref(null);

const title = computed(() => props.record.id ? 'Edit video/reel' : 'New video/reel');

watch(
  () => props.record,
  () => {
    editingRecord.value = cloneDeep(props.record);
    if (!editingRecord.value.videoLink) {
      editingRecord.value.videoLink = { url: null };
    }
  },
  { immediate: true },
);

/// methods

function cancel() {
  emit('close', { action: 'cancel' });
}

const vm = getCurrentInstance();

async function saveRecord() {
  const invalid = checkForm(vm);
  if (invalid.length === 0) {
    try {
      const record = await actorVideosStore.save(editingRecord.value);
      emit('close', { action: 'save', record });
    } catch (ex) {
      if (isGraphQLError(ex)) {
        showError({ message: ex.message });
      }
      ReportError(ex);
    }
  } else {
    console.info(invalid);
    showError({ message: 'Form is not valid! Please check the fields.' });
  }
}

async function deleteRecord() {
  const result = await confirm({
    message: 'Delete this video/reel?',
  });

  if (result.action === 'ok') {
    try {
      const actorVideoId = await useActorVideoStore().delete(editingRecord.value.id);
      emit('close', { action: 'delete', actorVideoId });
    } catch (ex) {
      if (isGraphQLError(ex)) {
        showError({ message: ex.message });
      }
      ReportError(ex);
    }
  }
}

function prefixUrl() {
  const value = editingRecord.value.videoLink.url;
  let newValue = null;
  if (value) {
    const pos = value.indexOf(':');
    if (pos < 1) {
      newValue = `http://${trimStart(value, '/:')}`;
    }
  }
  if (newValue) {
    editingRecord.value.videoLink.url = newValue;
    nextTick(() => {
      urlRef.value.checkHtml5Validity();
    });
  }
}

</script>

<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title {{ title }}
  section.modal-card-body
    OField(label="Name")
      OInput(v-model.trim="editingRecord.name" required)
    OField(label="Video")
      OInput(
        ref="urlRef"
        v-model.trim="editingRecord.videoLink.url"
        type="url"
        placeholder="https://www.youtube.com/watch?v="
        required
        @blur="prefixUrl"
      )
  footer.modal-card-foot
    OButton(@click="cancel") Cancel
    OButton(variant="primary" @click="saveRecord") Save
    .bottom-right-actions
      OButton(v-if="editingRecord.id" variant="danger" @click="deleteRecord") Delete
</template>

<style scoped>
</style>
