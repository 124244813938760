<script setup>
import { ref } from 'vue';
import EditActorHeadshotDialog from '@/dialogs/EditActorHeadshotDialog.vue';
import { openDialog } from '@/dialogs';
import { HEADSHOT_FORMATS } from '@/utilities/constants.js';

const props = defineProps({
  actorId: {
    type: String,
    required: true,
  },
});

const droppedFile = ref(null);

async function handleUpload(file) {
  if (!file) return;

  const record = { actorId: props.actorId };

  await openDialog(EditActorHeadshotDialog, {
    record,
    file: file,
  });
  droppedFile.value = null;
}
</script>

<template lang="pug">
section.upload-wrapper
  OUpload(
    v-model="droppedFile"
    expanded
    drag-drop
    :accept="HEADSHOT_FORMATS.join(',')"
    @update:model-value="handleUpload"
  )
    p
      OIcon(icon="upload")
      br
      | Drop here or click to upload
      br
      i a single image up to 5MB in size
</template>

<style scoped lang="scss">
@import "bulma/sass/utilities/_all.sass";

.upload-wrapper {
  flex-grow: 1;
  text-align: center;
  display: inline-flex;
}
</style>
