<script setup>
import { openDialog } from '@/dialogs';
import EditActorHeadshotDialog from '@/dialogs/EditActorHeadshotDialog.vue';
import ActorHeadshot from '@/components/ActorHeadshot.vue';
import { useActorStore } from '@/stores/actors.js';
import { computed } from 'vue';

const actorsStore = useActorStore();

const props = defineProps({
  editable: {
    type: Boolean,
    default: false,
  },
  actorHeadshot: {
    type: Object,
    required: true,
  },
  allowZoom: Boolean,
});

const isPrimary = computed(() => {
  if (!props.actorHeadshot?.actorId) return false;
  const actor = actorsStore.retrieve(props.actorHeadshot.actorId);
  return actor?.primaryActorHeadshotId === props.actorHeadshot.id;
});

async function editRecord() {
  await openDialog(EditActorHeadshotDialog, {
    record: props.actorHeadshot,
  });
}

async function makePrimary() {
  await actorsStore.setPrimaryHeadshot(
    props.actorHeadshot.actorId,
    props.actorHeadshot.id,
  );
}
</script>

<template lang="pug">
.card-image
  figure.image.is-96x96.is-centered
    ActorHeadshot(
      :actor-headshot-id="actorHeadshot.id"
      :allow-zoom="allowZoom"
    )
.card-content
  .content
    div
      strong(style="word-break: break-word") {{ actorHeadshot.name }}
.card-footer(v-if="editable")
  a.card-footer-item(@click="editRecord")
    | Edit
    |
    OIcon(icon="pen-to-square")
  .card-footer-item(v-if="isPrimary")
    strong Primary
  a.card-footer-item(v-else @click="makePrimary")
    | Make Primary
</template>

<style scoped>
</style>
