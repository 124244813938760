<script setup>
import { useActorStore } from '@/stores/actors.js';
import { useSkillStore } from '@/stores/skills';
import { computed, ref, watch } from 'vue';
import { colorProps } from '@/utilities/colors.js';
import SkillTagList from '@/components/SkillTagList.vue';
import { buildAddRemoveChanges, groupBy, sortMap } from '@/utilities/helpers.js';
import { confirm, showError } from '@/dialogs/index.js';
import { isGraphQLError } from '@/api/utils.js';
import { ReportError } from '@/error_handling.js';

const props = defineProps({
  actorId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

defineOptions({
  dialogOptions: {
    fullScreen: true,
    canCancel: ['button'],
  },
});

const skillsStore = useSkillStore();
const actorsStore = useActorStore();

skillsStore.sync();

const editingSkillIds = ref(new Set());

const currentSkillIds = computed(() => {
  return new Set(actorsStore.retrieve(props.actorId).skillIds);
});


watch(
  currentSkillIds,
  () => {
    editingSkillIds.value = new Set(currentSkillIds.value);
  },
  { immediate: true },
);

const skillsByCategory = computed(() => {
  const skills = skillsStore.records.filter(s => !editingSkillIds.value.has(s.id));
  const grouped = groupBy(skills, skill => skill.category);
  return sortMap(grouped, {
    valueSort: (a, b) => a.skill > b.skill ? 1 : -1,
  });
});

const changeSet = computed(() =>
  buildAddRemoveChanges(
    currentSkillIds.value,
    editingSkillIds.value,
    { includeEmpty: true },
  ));

// methods
function cancel() {
  emit('close', { action: 'cancel' });
}

async function save() {
  try {
    await actorsStore.save({
      id: props.actorId,
      addSkillIds: changeSet.value.created,
      removeSkillIds: changeSet.value.deleted,
    });
    emit('close', { action: 'ok' });
  } catch (ex) {
    if (isGraphQLError(ex)) {
      showError({ message: ex.message });
    }
    ReportError(ex);
  }
}

async function addSkill(skill) {
  editingSkillIds.value.add(skill.id);
}

async function removeSkill(skill) {
  editingSkillIds.value.delete(skill.id);
}

async function confirmCancel() {
  if (changeSet.value.created.length > 0 || changeSet.value.deleted.length > 0) {
    const resp = await confirm({
      message: 'There are unsaved changes, do you want to dismiss them?',
    });
    if (resp.action !== 'ok') {
      return;
    }
  }
  cancel();
}
</script>

<template lang="pug">
.modal-card
  header.modal-card-head
    p.modal-card-title Editing actor skills
    button.delete(aria-label="close" @click="confirmCancel")
  section.modal-card-body
    .tile.is-ancestor
      .tile.is-parent
        .tile.is-child
          p.title.is-6 Selected skills
          SkillTagList(
            deletable
            :skill-ids="editingSkillIds"
            empty-message="No skills selected"
            @delete="removeSkill"
          )
    hr
    template(v-for="[category, skills] in skillsByCategory" :key="category")
      p.title.is-4.skill_category {{ category }}
      .tags.are-medium
        .tag.is-rounded.is-clickable(
          v-for="skill in skills"
          :key="skill.id"
          :class="colorProps(skill.options.colorName)?.classes"
          @click="addSkill(skill)"
        ) {{ skill.skill }}
  footer.modal-card-foot
    OButton(@click="cancel") Cancel
    OButton(variant="primary" @click="save") Save
    .level
      .level-left
        .level-item(v-if="changeSet.created.length > 0")
          strong.mx-1 Will add:
          | {{ changeSet.created.length }}
        .level-item(v-if="changeSet.deleted.length > 0")
          strong.mx-1 Will remove:
          | {{ changeSet.deleted.length }}
</template>

<style scoped>

</style>
